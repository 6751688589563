html {
  font: 16px/100% Arial, Sans-Serif;
}

h1,
h2,
h3 {
  line-height: 120%;
}

h1 {
  font-size: 30px;
  margin: 0 0 24px;
}

h2 {
  font-size: 26px;
  margin: 0 0 18px;
}

h3 {
  font-size: 20px;
  margin: 0 0 15px;
}

p,
.wh-video,
.wh-rtd-embeddedobject,
.cp-container {
  margin: 0 0 12px;
}

a {
  color: inherit;
  text-decoration: underline;
}

a:hover {
  text-decoration: none;
}

ul {
  padding-left: 1.3em;
}

ol {
  padding-left: 1em;
}
