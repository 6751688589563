.song {
  font-family: "Lato", sans-serif;
  margin: 0 auto;
  width: 100%;
  max-width: 1000px;
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.song__top {
  margin-top: 20px;
}
.song__bottom {
  margin-bottom: 20px;
}
.song__player {
  flex: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.song__rtd > *:last-child {
  margin-bottom: 0;
}
.song__rtd--hasbg {
  padding: 15px;
}
.song__bg {
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.song audio {
  margin: 5px 0;
  width: 100%;
}
.song__warning {
  border: 1px solid #000;
  padding: 15px;
  margin: 0 0 25px;
}

@media screen and (min-width: 768px) {
  audio::-internal-media-controls-download-button {
    display: none;
  }
  audio::-webkit-media-controls-enclosure {
    overflow: hidden;
  }
  audio::-webkit-media-controls-panel {
    width: calc(100% + 30px);
  }
}
.prototype-wrapper {
  width: 200px;
  margin: 0 auto;
  transform: scale(0.6);
}
.prototype-wrapper #cp_container_1 {
  width: 200px;
  height: 200px;
}