@import url("https://fonts.googleapis.com/css?family=Lato");
html, body, form {
  padding: 0;
  margin: 0;
}

img {
  border: none;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary {
  display: block;
}

audio, canvas, video {
  display: inline-block;
}

datalist, template {
  display: none;
}

*, *:before, *:after {
  box-sizing: border-box;
}

body {
  -webkit-text-size-adjust: none;
  -ms-text-size-adjust: none;
  -moz-text-size-adjust: none;
  text-size-adjust: none;
}

button {
  border: 0;
  border-radius: 0;
  background: none;
  cursor: pointer;
}

@font-face {
  font-family: "bebas_neueregular";
  src: url("./shared/fonts/bebasneue_regular-webfont.woff2") format("woff2"), url("./shared/fonts/bebasneue_regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
html,
body,
main {
  height: 100vh;
  min-height: 100%;
}

html.page-song main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 15px;
}

html.page-song.too-high main {
  display: block;
}